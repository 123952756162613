import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import psychadeliccat from "../images/gif/psychadeliccat.gif";
// import internet from "../images/gif/internet.gif";
import jimi from "../images/gif/jimi.gif";
// import soar from "../images/gif/letyourspiritsoar.gif";
import sunset from "../images/gif/sunset.gif";
// import vangif from "../images/gif/vangough.gif";
import weeddance from "../images/gif/weedleafdance.gif";
import box from "../images/gif/box.gif";
import city from "../images/gif/city.gif";
import spacecomputer from "../images/gif/spacecomputer.gif";
import coast from "../images/gif/coast.gif";
import earth from "../images/gif/earth.gif";

import "../css/style.css";

function GifsPage() {
    return (
        <Layout>
            <SEO
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                title="About"
            />
            <section className="flex justify-center align-center flex-wrap">
                <img className="iconStyleThree" src={psychadeliccat} />
                <img className="iconStyleThree" src={spacecomputer} />
                <img className="iconStyleThree" src={jimi} />
                <img className="iconStyleThree" src={earth} />
                <img className="iconStyleThree" src={sunset} />
                <img className="iconStyleThree" src={coast} />
                <img className="iconStyleThree" src={weeddance} />
                <img className="iconStyleThree" src={box} />
                <img className="iconStyleThree" src={city} />
            </section>
        </Layout>
    );
}

export default GifsPage;
